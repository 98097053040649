import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/servicios/api.service';
import { ModalController } from '@ionic/angular';

import { environment } from '../../../environments/environment';
import { Device } from '../../interfaces/device';
import { PopupMensajesService } from '../../servicios/popup-mensajes.service';
import { DataLocalService } from '../../servicios/data-local.service';

@Component({
  selector: 'app-nuevo-dispositivo',
  templateUrl: './nuevo-dispositivo.page.html',
  styleUrls: ['./nuevo-dispositivo.page.scss'],
})
export class NuevoDispositivoPage implements OnInit {
  dispositivo: Device;
  uniqueId = '';
  name = '';
  category = '';
  phone: string = null;
  speedLimit: number = null;
  categories = environment.deviceCategories;

  get dispositivos() {
    return this.dataLocalService.dispositivos;
  }

  constructor(
    private modalCtrl: ModalController,
    private popupMensajesService: PopupMensajesService,
    private apiService: ApiService,
    private dataLocalService: DataLocalService
  ) { }

  ngOnInit() {
  }

  cerrar(){
    this.modalCtrl.dismiss();
  }

  permitirGuardar(){
    if (this.uniqueId.length > 0 && this.name.length > 0){
      return false;
    }else{
      return true;
    }
  }

  guardar(){
    const d = new Date();
    this.dispositivo = {
      id: -1,
      name: this.name,
      uniqueId: this.uniqueId,
      status: null,
      disabled: false,
      lastUpdate: null,
      positionId: 0,
      groupId: 0,
      phone: this.phone,
      model: '',
      contact: '',
      category: this.category,
      geofenceIds: [],
      attributes: {
              speedLimit: this.speedLimit,
              creationDate: d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate(),
              expirationDate: (d.getFullYear()+1) + '-' + (d.getMonth()+1) + '-' + d.getDate()
            }
    };
    this.apiService.dispositivosPOST('POST','',this.dispositivo).subscribe(
      response => {
        this.dispositivos.push(response);
        this.cerrar();
      },
      error => {
        this.popupMensajesService.presentAlert(error);
      }
    );
  }

}
